// const NavbarData = [
//     { title: 'Home', path: '/' },
//     { title: 'About', path: '/about' },
//     // Dodajte ostale stavke navigacije prema potrebi...
//   ];

//   export default NavbarData;

// export const NavbarData = [
//   {
//     title: "Takmičenje",
//     cName: "nav-links",
//     hasSubMenu: true,
//     subMenu: [
//       {
//         title: "O takmičenju",
//         url: "/otakmicenje",
//       },
//       {
//         title: "Pravila takmičenja",
//         url: "/pravila",
//       },
//       {
//         title: "MeetUp",
//         url: "/meetup",
//       },
//     ],
//   },
//   {
//     title: "Vesti",
//     cName: "nav-links",
//     hasSubMenu: true,
//     subMenu: [
//       {
//         title: "Novosti",
//         url: "/vesti",
//       },
//       {
//         title: "Edu kutak",
//         url: "/edukutak",
//       },
//     ],
//   },
//   {
//     title: "Partneri",
//     url: "/partneri",
//     cName: "nav-links",
//   },
//   {
//     title: "Organizatori",
//     url: "/organizatori",
//     cName: "nav-links",
//   },
// ];

export const NavbarData = [
  // {
  //   title: "Prijava",
  //   cName: "nav-links",
  //   hasSubMenu: true,
  //   subMenu: [
  //     {
  //       title: "Radionice",
  //       url: "/prijava",
  //     },
  //     {
  //       title: "Design Insight",
  //       url: "/prijava-design-insight",
  //     },
  //   ],
  // },
  {
    title: "Projekat",
    cName: "nav-links",
    hasSubMenu: true,
    subMenu: [
      {
        title: "Design Insight",
        url: "/projekat/design-insight",
      },
      // {
      //   title: "Galerija",
      //   url: "/projekat/galerija",
      // },
      {
        title: "Program",
        url: "/projekat/program",
      },
    ],
  },
  {
    title: "Radionice",
    cName: "nav-links",
    hasSubMenu: true,
    subMenu: [
      {
        title: "Graphic Design Basic",
        url: "/radionice/graphic-design-basic",
      },
      {
        title: "Graphic Design Advanced",
        url: "/radionice/graphic-design-advanced",
      },
      {
        title: "Web Design HTML/CSS",
        url: "/radionice/web-design-HTML/CSS",
      },
      {
        title: "Web Design React",
        url: "/radionice/web-design-React",
      },
      {
        title: "Digital Photography",
        url: "/radionice/digital-photography",
      },
      {
        title: "Video Editing",
        url: "/radionice/video-editing",
      },
    ],
  },
  {
    title: "Vesti",
    cName: "nav-links",
    hasSubMenu: true,
    subMenu: [
      {
        title: "Novosti",
        url: "/vesti",
      },
      {
        title: "Edukativne Vesti",
        url: "/edukutak",
      },
    ],
  },
  {
    title: "Partneri",
    url: "/partneri",
    cName: "nav-links",
  },
  {
    title: "Organizatori",
    url: "/organizatori",
    cName: "nav-links",
  },
  {
    title: "Kontakt",
    url: "/kontakt",
    cName: "nav-links",
  },
];
