import React from "react";
import "./Card.css";

const CardNagrade = ({ title, content, imageUrl, button, buttons }) => {
  return (
    <div className="cardNagrade">
      <div className="card-content">
        <h2 className="card-title card-title-nagrade">{title}</h2>
        <img src={imageUrl} className="card-image" alt={title} />
        <p className="card-text-nagrade">{content}</p>

        {buttons ? (
          <div className="button-container">
            {buttons.map((btn, index) => (
              <a key={index} href={btn.link} className="button-text">
                {btn.text}
              </a>
            ))}
          </div>
        ) : (
          button && (
            <a href={button.link} className="button-text">
              {button.text}
            </a>
          )
        )}
      </div>
    </div>
  );
};

export default CardNagrade;
