import React, { useState } from "react";
import "./radionice.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaHeart } from "react-icons/fa";
import slika1 from "./Slike/DigitalPhotography/DP10.jpg";
import slika2 from "./Slike/DigitalPhotography/DP11.jpg";
import slika3 from "./Slike/DigitalPhotography/DP13.png";
import slika4 from "./Slike/DigitalPhotography/DP17.jpg";
import slika5 from "./Slike/DigitalPhotography/DP16.jpg";
import slika6 from "./Slike/DigitalPhotography/DP18.jpg";
import slika7 from "./Slike/DigitalPhotography/DP22.jpg";
import slika8 from "./Slike/DigitalPhotography/DP15.png";
import slikatekst from "./Slike/DigitalPhotography/images.jpg";
import slikatekst2 from "./Slike/DigitalPhotography/6016.jpg";

const DigitalPhotography = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    slika1,
    slika2,
    slika3,
    slika4,
    slika5,
    slika6,
    slika7,
    slika8,
  ];

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };

  return (
    <div className="meetup">
      <div className="contentMeetup">
        <p className="razmak1"></p>
        <div className="naslov-centar">
          <h1 className="naslov"> Digital Photography </h1>
        </div>
        <p className="razmak1"></p>
        <div className="section">
          <div className="left">
            <img src={slikatekst} alt="Digital Photogrphy" />
          </div>
          <div className="right">
            <h1>KOME JE NAMENJENA RADIONICA I KOJI JE CILJ?</h1>
            <p>
              {" "}
              Radionica je namenjena svim studentima koji žele da nauče kako da
              naprave kvalitetnu fotografiju sa profesionalnom foto opremom.
              Potrebna je želja za učenjem i napredovanjem, kao i fotoaparat.
              Cilj je da uz pomoć profesionalca savladaju kompoziciju u
              fotografiji i poboljšaju svoje fotografske sposobnosti. Nakon
              završenog seminara svoje novostečeno znanje i veštine će prikazati
              kroz fotografije koje budu nastavili da stvaraju.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="left">
            <h1>KAKAV JE KONCEPT RADIONICE?</h1>
            <p>
              Radionica se sastoji iz teorijskog i praktičnog dela. Koncipirana
              je tako da studenti steknu elementarna znanja iz oblasti
              fotografije, koja će im pomoći da uređuju fotografije u privatne i
              profesionalne svrhe u programima kao što su Lightroom i Adobe
              Photoshop. U okviru teorijskog dela upoznaće se sa svim elementima
              koji su sastavni deo dobre fotografije, dok praktičan deo
              predstavlja fotografisanje na terenu.
            </p>
          </div>
          <div className="right">
            <img src={slikatekst2} alt="Digital Photography" />
          </div>
        </div>
        {/* <div className="section">
          <div className="predavac1">
            <img
              src="https://bdw.best.rs/wp-content/uploads/2023/04/Copy-of-katarina-autoportret-768x1158.jpg"
              alt="Katarina Markovic"
            />
          </div>
          <div className="right">
            <h1>Katarina Marković</h1>
            <p>
              Katarina Marković (1993) je diplomirana vizuelna umetnica na
              odseku za Digitalnu umetnost, na Fakultetu za medije i
              komunikaciju. Do sada je izlagala na mnogim izložbama u zemlji i
              regionu, grupno i samostalno (Pavane galerija, Rovinj Photodays,
              Sarajevo Photography Award, Photon galerija, Kulturforum, Artget).
              Fokus njene fotografije su portreti i režirane scene koje su
              inspirisane raličitim uticajima iz istorije umetnosti i filma. Od
              2021.godine deo je zajedničkog umetničkog prostora Studija15, koji
              je nastao kao alternativni prostor za stvaranje, radionice,
              izložbe i bilo koji vid kreativnog stvaralaštva. Od 2022.godine
              radi kao zvanični fotograf i vizuelni direktor European Graduate
              School (EGS/PACT). Njeni radovi se mogu naći na ketmarkovic.com
            </p>
            <div className="social-icons">
              <a href="https://www.facebook.com/best.beograd/">
                <FaFacebook />
              </a>
              <a href="https://www.instagram.com/best_belgrade/">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com/company/best-belgrade/">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div> */}
        <div className="naslov-centar">
          <h1 className="naslov1">Radovi učesnika</h1>
        </div>
        <div className="section"></div>
        <div className="slideshow-container5">
          <button className="prev" onClick={prevSlide}>
            &#10094;
          </button>
          <img
            className="slideshowslike"
            src={slides[currentSlide]}
            alt={`Slide ${currentSlide + 1}`}
          />
          <button className="next" onClick={nextSlide}>
            &#10095;
          </button>
        </div>
      </div>
      <div className="razmak"></div>
    </div>
  );
};

export default DigitalPhotography;
