// VestiData.js
import slika1 from "./VestiSlike/OrgTim.jpg";
import tim from "./VestiSlike/tim.jpg";
import metropolitan from "./VestiSlike/metropolitanVest.png";
import metropolitanTakmicenje from "./VestiSlike/metropolitanTakmicenje.jpg";
import vesti3 from "./VestiSlike/vesti3.png";
import vesti2 from "./VestiSlike/vesti2.png";
import kuceriz1 from "./VestiSlike/kuceriz1.JPG";
import kuceriz2 from "./VestiSlike/kuceriz2.CR2";

// const VestiData = () => {
export const VestiData = [
  {
    naslov: "Otvorene su prijave za BEST design WEEK 2024. godine!",
    kratakSadrzaj:
      "Upravo je otvorene prijave za najpraktičniji i potpuno besplatan seminar dizajna i brendiranja – ‚‚BEST design WEEK”, koji će da se održi četrnaesti put zaredom od 18. do 24. maja 2024. godine.",
    ceoSadrzaj:
      "‚‚Želim da se usavršim na polju dizajna, ali ne znam odakle da počnem. Većina kurseva je skupa i nudi preopširno znanje koje prevazilazi moju zainteresovanost. Nisam siguran/na sa kim da se posavetujem.”",
    dodatniTekst: (
      <div><p><b>Ako i tebi ove misle prolaze kroz glavu, onda je ova vest baš za tebe!</b>
      <br /><br /> <b>Upravo je otvorene prijave</b> za najpraktičniji i potpuno besplatan seminar dizajna i brendiranja – <b>‚‚BEST design WEEK”</b>, koji će da se održi četrnaesti put zaredom <b>od 18. do 24. maja 2024. godine</b>.
       <br /><br /> <b>Ovaj seminar ti nudi 6 različitih radionica:
        <ul><li>Dve radionice Graphic design-a (Basic i Advanced)</li>
        <li>Dve radionice Web design-a (HTML/CSS i React)</li>
        <li>Digitalnu fotografiju</li>
        <li>Video editing</li></ul></b>
        <br /> <br />Pored radionica spremili smo i jedan dan namenjen predavanjima i diskutovanju o najaktuelnijim temama današnjice iz sveta dizajna. Reč je o <b>Design Insight-u</b> koji će se održati <b>8. maja 2024. godine</b>. 
        <br /><br /><b>POŽURI sa svojom prijavom, jer prijavljivanje moguće do 9. maja u 23:59h!</b>
        </p>
      </div>
    ),
    slika: vesti2,
    index: "otvorene-prijave",
    datum: "24.04.2024.",
    autor: "Natalija Okrajnov",
  },

  
  {
    naslov: "Šta možete da očekujete od četrnaestog zaredom ‚‚BEST design WEEK-a”?",
    kratakSadrzaj:
      "Udruženje studenata tehnike Evrope — BEST Beograd, četrnaesti put zaredom organizuje seminar dizajna i brendiranja, BEST design WEEK,  od 18. do 24. maja 2024. godine, koji će se održati na Fakutetu oranizacionih nauka.",
    ceoSadrzaj:
      "Udruženje studenata tehnike Evrope — BEST Beograd, četrnaesti put zaredom organizuje seminar dizajna i brendiranja, BEST design WEEK,  od 18. do 24. maja 2024. godine, koji će se održati na Fakutetu oranizacionih nauka. ",
    dodatniTekst: (
      <div>
        <p>
        Seminar je originalan projekat lokalne grupe BEST Beograd, koji u kontinuitetu već dugi niz godina uspešno plasira kvalitetno obrazovanje od strane najaktuelnijih stručnjaka sa našeg tržišta. Kako je seminar  prepoznatljiv po svom kvalitetnom edukativnom programu, tako je i po vizuelnom identitetu, kreativnosti i inovativnosti.
        <br /> Radionice su namenjene svim zainteresovanim studentima, bez obzira na to da li oni imaju ili nemaju dosadašnje iskustvo iz ponuđenih oblasti. Na taj način, pohađanje seminara je idealna prilika da se stekne novo, ili po mogućnosti, unapredi već stečeno znanje. Cilj projekta je da se obezbedi besplatno savladavanje, napredovanje i usavršavanje na polju grafičkog dizajna, web dizajna, digitalne fotografije i video montaže. 
        <br /><br />Seminar se sastoji iz šest radionica na kojima se obrađuju sledeće teme: 
        <ul><li><b>Graphic Design Basic</b> – radionica na kojoj se studenti upoznaju sa osnovama grafičkog dizajna i korišćenjem adekvatnih softvera koji im omogućavaju da lako savladaju proces od ideje do realizacije.</li>
        <li><b>Graphic Design Advanced</b> – omogućava studentima, koji već imaju predznanje u ovom polju,  proširenje svog dosadašnjeg znanja i vidika. Podstaći će napredak svojih veština za korišćenje programa iz oblasti grafičkog dizajna i usavršiće ih kroz rad na brendiranju, odnosno na kreiranju vizuelnog identiteta. </li>
        <li><b>Web Design HTML/CSS</b> – program je namenjen studentima koji imaju želju da probude svoje potencijale, sticanjem osnovnog znanja iz web dizajna pomoću alata koje nude programski jezici HTML i CSS. Takođe, steći će i osnovna tehnička znanja o načinu funkcionisanja web sajtova.</li>
        <li><b>Web Design React</b> – idealan je za polaznike koji dobro poznaju osnove web dizajna i koji su savladali pisanje kodova, ali im je potrebna pomoć i dodatno znanje o mogućnostima koje koji nudi React, kako bi učinili svoj sajt još boljim.</li>
        <li><b>Digital Photography</b> – predstavlja radionicu namenjenu svim studentima zainteresovanim za izradu visoko kvalitetne fotografije, željnim da nauče koje su to osnove i teorijska znanja koja će im koristiti u budućem radu. Na samom kraju seminara imaće priliku da praktično primene svoje veštine fotografisanja.</li>
        <li><b>Video Editing</b> – s obzirom  na širinu primene audio i video zapisa, nezamislivo je izostavljanje ove radionice sa seminara. Njeni polaznici imaju mogućnost da steknu osnovna znanja obuhvaćena režijom i radom u softveru namenjenom za takav posao. Svim studentima, koji se deklarišu kao ljubitelji filma, omogućeno je da se oprobaju u ovom divnom poslu.</li>
</ul><br />Pored navedenih radionica organizujemo i dan predavanja posvećen ažurnostima savremenog dizajna – Design Insight koji će se održati 8. maja. Cilj predavanja je da podstaknemo kreativnost studenata zainteresovanih da se bave dizajnom i brendiranjem, motivišemo njihov napredak kroz zanimljiva predavanja predavača i stručnjaka u svojoj oblasti. U okviru ovog događaja, studenti će imati mogućnost da diskutuju sa najuticajnijim dizajn stručnjacima sa naših prostora. Predavanju će moći da prisustvuju svi učesnici, kao i zainteresovani studenti koji nisu učesnici radionica, uz prijavu.
<br /><b>Prijave</b> za učešće na <b>BEST design WEEK-u </b>su otvorene <b>od 24. aprila do 9. maja</b> na sajtu bdw.best.rs, gde možete naći više informacija o projektu. Sve radionice su <b>besplatne</b>, a po završetku seminara, učesnici će dobiti <b>sertifikat o učešću</b>.


        </p>
      </div>
    ),
    slika: vesti3,
    index: "četrnaesti-bdw",
    datum: "15.03.2024.",
    autor: "Natalija Okrajnov",
  },
  {
    naslov: "Upoznajte 14. organizacioni tim BEST design WEEK-a!",
    kratakSadrzaj:
      "Već četrnaest godina zaredom, Udruženje studenata tehnike Evrope – BEST Beograd organizuje svoj originalan projekat ‚‚BEST design WEEK”. Reč je o seminaru dizajna i brendiranja, koji će se održati od 18. do 24. maja 2024. godine.",
    ceoSadrzaj:
      "Već četrnaest godina zaredom, Udruženje studenata tehnike Evrope – BEST Beograd organizuje svoj originalan projekat ‚‚BEST design WEEK”. Reč je o seminaru dizajna i brendiranja, koji će se održati od 18. do 24. maja 2024. godine. ",
    dodatniTekst: (
      <div>
        <p>
        Ovogodišnji organizacioni tim čini šestoro mladih i talentovanih studenata, vrlo različitih interesovanja, koji su se okupili da vam prirede ‚‚BEST design WEEK” u što kvalitetnijem izdanju. <br></br><br></br>
        </p>
        <p>
        Predstavljamo vam ovogodišnji organizacioni tim:
        </p>
        <p>
        <b> Anđela Kovačević </b>– glavni organizator, studentkinja Saobraćajnog fakulteta <br></br>
<b>Una Kovačević </b>– koordinator za seminar, studentkinja Poljoprivrednog fakulteta <br></br>
<b>Anđela Petričković </b>– koordinator za odnose sa kompanijama, studentkinja Fakulteta organizacionih nauka <br></br>
<b>Natalija Okrajnov </b>– koordinator za marketing i odnose sa javnošću, studentkinja Farmaceutskog fakulteta <br></br>
<b>Teodora Stanojević</b> – koordinator za dizajn, studentkinja Mašinskog fakulteta <br></br>
<b>Pavle Bićanin </b>– koordinator za logistiku, student Saobraćajnog fakultet <br></br>
        </p>

      </div>
    ),
    slika: tim,
    index: "organizacioni-tim",
    datum: "20.05.2024.",
    autor: "Natalija Okrajnov",
  },
];
