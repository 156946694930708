// cardData.js
export const cardData = [
  {
    title: "O projektu BEST design WEEK",
    content:
      "BEST design WEEK je seminar dizajna i brendiranja koji će ove godine biti održan četrnaesti put zaredom u Beogradu od 18. do 24. maja u organizaciji Udruženja studenata tehnike Evrope – Beograd. Studenti će imati priliku da učestvuju u radionicama i predavanjima eminentnih predavača iz oblasti brendiranja, grafičkog i web dizajna, digitalne fotografije i video montaže. Cilj projekta je da se studentima omogući savladavanje ili usavršavanje principa dizajna i brendiranja. Kroz interaktivan rad sa predavačima u softverskim programima studenti će tokom sedam dana seminara raditi na dizajnu različitih proizvoda, obrađivati razne aktuelne i interesantne teme i tako doći do finalnog dizajnerskog rešenja. Po završetku seminara, svi učesnici dobiće sertifikat o učešću na BEST design WEEK-u.",
   
  },
  // {
  //   title: "WARMUP",
  //   content:
  //     "WarmUp je niz uvodnih aktivnosti koji će se održati u nedelji pre samog takmičenja.Ovaj dodatni sadržaj namenjen je isključivo takmičarima i njegov cilj je da na adekvatan način uvede takmičare u vikend koji je pred njima. Na online druženju, takmičari će se upoznati i pripremiti za intenzivnu i fer borbu.",
  // },
  // {
  //   title: "MEETUP",
  //   content:
  //     "Lokalna grupa BEST Beograd i ove godine, u okviru projekta AIBG, pored samog takmičenja, organizuje i edukativni deo Make IT Learn MeetUp. MeetUp predstavlja predavanja na temu veštačke inteligencije i mašinskog učenja, sa ciljem da se studenti upoznaju sa konceptom i trendovima iz ovih sve prisutnijih oblasti.",
  //   button: "/meetup",
  // },
];
