import React from "react";
import { FaMapMarker, FaPhone, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom"; // Uvezite Link komponentu
import "./Kontakt.css";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Kontakt = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_eczycey",
        "template_hukft5y",
        form.current,
        "Nnua2ZMR3r6lAITQZ"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Poruka je uspešno poslata!");
        },
        (error) => {
          console.log(error.text);
          alert("Došlo je do greške!");
        }
      );
    e.target.reset();
  };
  return (
    <div className="contact-container">
      <div className="header-razmak"></div>
      <div className="contact-form">
        <form ref={form} onSubmit={sendEmail}>
          <div className="naslov-centar">
            <h1 className="naslovStranice">KONTAKT</h1>
          </div>
          <div className="form-left">
            <div className="form-group">
              <label>Vaše ime (obavezno)</label>
              <input type="text" name="ime" required />
            </div>
            <div className="form-group">
              <label>Vaša е-pošta (obavezno)</label>
              <input type="text" name="email" required />
            </div>
            <div className="form-group">
              <label>Tema</label>
              <input type="text" name="tema" />
            </div>
            <div className="form-group">
              <label>Vaša poruka</label>
              <textarea className="txtporuka" name="poruka"></textarea>
            </div>
            <button className="dugme">Pošalji</button>
          </div>
          <div className="form-right">
            <div className="map-container">
              {/* Google Map embed code with the new location URL */}
              <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.492348147172!2d20.46540591556733!3d44.81180169327287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a70085798f471%3A0x85a701b757913d56!2sZadruga%20studenata%20Univerziteta%20u%20Beogradu!5e0!3m2!1sen!2srs!4v1661841501509!5m2!1sen!2srs"
                width="100%"
                height="300"
                frameBorder="0"
                style={{ border: "0" }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
            <div className="contact-info">
              <h3>UDRUŽENJE STUDENATA TEHNIKE EVROPE - BEOGRAD</h3>
              <p>
                <FaMapMarker /> Adresa: Karnegijeva 4, Beograd
              </p>
              <p>
                <FaPhone /> +381 11 3370545
              </p>
              <p>
                <FaEnvelope /> beograd@best-eu.org
              </p>
            </div>
          </div>
        </form>
        <button>
          <Link className="dugme" to="/organizatori">
            Organizacioni tim
          </Link>
        </button>
      </div>
      <div className="footer-razmak"></div>
      <div className="header-razmak"></div>
    </div>
  );
};

export default Kontakt;
