import React from "react";
import "./radionice.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaHeart } from "react-icons/fa";
import { useState } from "react";
import slika1 from "./Slike/GraphicDesignBasic/GB5.jpg";
import slika3 from "./Slike/GraphicDesignBasic/GB6.jpg";
import slika2 from "./Slike/GraphicDesignBasic/GB7.jpg";
import slika4 from "./Slike/GraphicDesignBasic/GB8.jpg";

const GraphicDesignBasic = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [slika1, slika2, slika3, slika4];
  const nextSlide = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };
  return (
    <div className="meetup">
      <div className="contentMeetup">
        <p className="razmak1"></p>
        <div className="naslov-centar">
          <h1 className="naslov"> Graphic Design Basic </h1>
        </div>
        <p className="razmak1"></p>

        <div className="section">
          <div className="left">
            <img
              src="https://www.bolton.ac.uk/assets/Uploads/Graphic-design-1.png"
              alt="Graphic Design"
            />
          </div>
          <div className="right">
            <h1>KOME JE NAMENJENA RADIONICA I KOJI JE CILJ?</h1>
            <p>
              {" "}
              Graphic Design Basic je radionica koja je prilagođena svim
              studentima koji žele da nauče osnove grafičkog dizajna. Za ovu
              radionicu nije neophodno predznanje iz ove oblasti ili poznavanje
              softverskih programa koji se koriste u grafičkom dizajnu. Potrebna
              je samo kreativnost i želja za učenjem.Cilj je da nakon 6 dana
              samostalnog i interaktivnog rada sa predavačima, učesnici
              radionice prezentuju svoj finalni rad koji je rezultat
              novostečenih znanja i veština.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="left">
            <h1>KAKAV JE KONCEPT RADIONICE?</h1>
            <p>
              Radionica se sastoji iz teorijskog i praktičnog dela. U okviru
              teorijskog dela, učesnici će se uz pomoć predavača upoznati sa
              osnovama dizajna, kao i konceptualizacijom ideje – kako doći do
              pravog odgovora na zadatak. Kasnije će svoje teorijsko znanje
              primeniti praktično, gde će im predavači pomoći da koristeći
              softverske alate (Adobe Photoshop i Adobe Ilustrator) realizuju
              svoju ideju. Trajanje radionice: 6 dana (od 18. do 24. maja)
              Trajanje časa po danu: 1h i 15 miuta + 30 minuta pauze + 1h i 15
              minuta Maksimalni broj izostanaka sa kojim se dobija sertifikat o
              učešću na radionici je 2.
            </p>
          </div>
          <div className="right">
            <img
              src="https://media.licdn.com/dms/image/D4D12AQHYC_ZczxV2zw/article-cover_image-shrink_720_1280/0/1695524296879?e=1719446400&v=beta&t=vu9LYKhwtRK2Da4JHAW6r5ga3CYSDovEbroFBREvTzs"
              alt="Graphic Design "
            />
          </div>
        </div>
        {/* <div className="section">
          <div className="predavac1">
            <img
              src="https://bdw.best.rs/wp-content/uploads/2023/04/72A41C00-3115-4D11-8DBF-A03382E6C91A-transformed-1-768x1152.jpeg"
              alt="Matija Simovic "
            />
          </div>
          <div className="right">
            <h1>Matija Simović</h1>
            <p>
              Diplomirao je na Fakultetu primenjenih umetnosti, Univerziteta
              umetnosti u Beogradu, student generacije modula Grafički dizajn.
              Aktivno učestvuje i volontira u mnogim organizacijama. Učestvovao
              je na mnogobrojnim izložbama, umetničkim radionicama, likovnim
              kolonijama i konkursima u zemlji i inostranstvu. Izlagao je u
              Njujorku, Beogradu, Mariboru, Nišu, Aranđelovcu, Kučevu, Rabrovu.
              Imao je do sada tri samostalne izložbe. Dobitnik je nagrada iz
              oblasti grafičkog dizajna. Art direktor svečane Svetosavske
              akademije u Beogradu, najvećeg državnog događaja iz oblasti
              obrazovanja u zemlji. Matija je radio na velikom broju projekata,
              sarađivao sa institucijama i kompanijama od kojih izdvaja:
              Ministarstvo prosvete RS, Ministarstvo kulture RS, Ministarstvo
              turizma i telekomunikacija RS, Atelje 212, Studentski kulturni
              centar, Fakultet dramskih umetnosti u Beogradu, Univerzitet
              umetnosti u Beogradu, Dečji kulturni centar Beograd, Filmkultura,
              Birra Morreti, Star Bet, MPC Properties, Savez studenata Beograd,
              Savez studenata Biološkog fakulteta u Beogradu, Palanačka
              gimnazija, Renovation Partners France, Perfectline Fitnes Centar,
              Institut za veštačku inteligenciju RS, Goša FOM, The UN Refugee
              Agency, World Youth Wave.
            </p>
            <div className="social-icons">
              <a href="https://www.facebook.com/best.beograd/">
                <FaFacebook />
              </a>
              <a href="https://www.instagram.com/best_belgrade/">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com/company/best-belgrade/">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div> */}
        <div className="naslov-centar">
          <h1 className="naslov1">Radovi učesnika</h1>
        </div>
        <div className="section"></div>
        <div className="slideshow-container5">
          <button className="prev" onClick={prevSlide}>
            &#10094;
          </button>
          <img
            className="slideshowslike"
            src={slides[currentSlide]}
            alt={`Slide ${currentSlide + 1}`}
          />
          <button className="next" onClick={nextSlide}>
            &#10095;
          </button>
        </div>
      </div>
      <div className="razmak"></div>
    </div>
  );
};

export default GraphicDesignBasic;
