import slika1 from "./EdukutakSlike/gojko.png";
import slika2 from "./EdukutakSlike/steva.png";
import slika3 from "./EdukutakSlike/bilja.jpg";
import slika4 from "./EdukutakSlike/sneki.jpg";

//import AImed1 from "./EdukutakSlike/3d-rendering-biorobots-concept.jpg";
import AImed1 from "./EdukutakSlike/vesti2.png";

//!!!!!! klase podvuceni i italic se nalaze u vesti.css

export const EdukutakData = [
  {
    naslov: "Uticajni Srbi iz sveta dizajna",
    kratakSadrzaj:
      "Iako je Srbija po površini mala država, za Srbe se itekako čuje nadaleko po svetu. Ponosimo se mnogim svetskim imenima koja su potekla sa našeg područja i ostavila veliki trag u svetskoj nauci i istoriji. Da li ste znali da pored srpskih vladara, naučnika i sportista postoje i srpski dizajneri za koje znaju mnogi narodi van naših granica?U ovom tekstu predstavićemo vam 4 svetski istaknuta srpska dizajnera.",
    ceoSadrzaj: (
      <>
        <p>
          Iako je Srbija po površini mala država, za Srbe se itekako čuje
          nadaleko po svetu. Ponosimo se mnogim svetskim imenima koja su potekla
          sa našeg područja i ostavila veliki trag u svetskoj nauci i istoriji.
          Da li ste znali da pored srpskih vladara, naučnika i sportista postoje
          i srpski dizajneri za koje znaju mnogi narodi van naših granica? U
          ovom tekstu predstavićemo vam 4 svetski istaknuta srpska dizajnera.
        </p>
        <div className="tekstDiv">
          {" "}
          <p className="umetnik">
            Gojko Varda – arhitekta unutrašnje arhitekture, dizajner i pedagog
          </p>
          <img className="slikaEdukutak" src={slika1} />
          <p>
            Diplomirao je na Akademiji za primenjene umetnosti u Beogradu 1960.
            godine. Varda je postao izuzetno poznat po svojoj posvećenosti poslu
            i izuzetnom talentu u industrijskom dizajnu. Osvojio je brojna
            priznanja i nagrade za svoj rad, što se moglo pročitati u
            mnogobrojnim člancima. Varda je sam izrađivao modele za svoje
            predmete, što je pokazalo njegovo duboko poznavanje materijala i
            veštinu. Fordova stipendija za dizajn, koju je dobio 1963. godine,
            mu je omogućila važne kontakte u svetu arhitekture i dizajna. Varda
            nije samo doprineo oblasti dizajna, već je bio i jedan od osnivača
            Škole za dizajn na Dedinju 1963. godine. Kao profesor i direktor
            škole, Varda je mentorisao mnoge generacije dizajnera, podstičući
            njihovo stvaralaštvo i radoznalost. Njegovo pedagoško znanje je
            prepoznato na mnogim kongresima i predavanjima.
          </p>
        </div>
        <div className="tekstDiv">
          {" "}
          <p className="umetnik">Stevan Aleksić - diplomirani scenograf</p>
          <img className="slikaEdukutak" src={slika2} />
          <p>
            Stevan je umetnik sa širokim spektrom interesovanja i stvaralaštva.
            Već kao student na Fakultetu primenjenih umetnosti u Beogradu,
            angažovan je na brojnim projektima za film, televiziju i pozorište.
            Njegova karijera obuhvata uloge vizuelnog umetnika, scenografa,
            ilustratora, glumca, režisera, montažera, grafičkog dizajnera i
            kreativnog pisca. Sa više od 15 godina iskustva u nastavi na
            Fakultetu savremenih umetnosti u Beogradu, preneo je svoje znanje i
            strast na nove generacije studenata. U saradnji sa Miroslavom
            Lakobrijom, kreira nezavisne filmove za festivale kratkog metra.
            Aktivan je član kreativnog tima Festivala Srpskog Filma Fantastike,
            odgovoran za vizuelni identitet festivala i nagrade. Njegova strast
            prema umetnosti i angažovanje na različitim projektima čine ga
            inspiracijom za mlade umetnike u regionu. Zahvaljujući svom radu na
            plakatu za „Terminator 2: Sudnji dan", popeo se na 3. mesto
            prestižne TOP 10 liste na poznatom AMP sajtu.
          </p>
        </div>
        <div className="tekstDiv">
          {" "}
          <p className="umetnik">Biljana Tipsarević – modna kreatorka</p>
          <img className="slikaEdukutak" src={slika3} />
          <p>
            Ona je uspešna modna dizajnerka sa diplomom Fakulteta tehničkih
            nauka i masterom iz industrijskog inženjerstva i menadžmenta. Njena
            prva kolekcija „Zeitgeist" privukla je pažnju na Beogradskoj nedelji
            mode, a od tada je kontinuirano stvarala elegantne i sofisticirane
            modne linije. Kolekcijom „The Countess" predstavila je Srbiju na
            prestižnoj „Mercedes Benz Nedelji mode Malte" i dobila priznanje od
            modne urednice italijanskog Vogue-a. Otvorivši svoj prvi modni
            atelje u centru Beograda, pružila je iskustvo kupovine svojim
            klijentkinjama. Kreativni opseg Biljane nije ograničen na večernje
            haljine; kolekcija „Unexpected" pokazuje njen talenat za autentične
            komade visokog kvaliteta. Njene kreacije spajaju klasiku i avangardu
            na jedinstven način, nudeći širok spektar odevnih komada za
            različite prilike. Njeni uspesi obuhvataju kolekcije poput „La
            Storia de una Dona", „Irresistible", „Modern Fairytale", „Romantic
            Seduction", i mnoge druge. Biljanin doprinos modnoj industriji
            ostaje neprocenjiv i inspirišući kako modne entuzijaste tako i one
            koji teže ostvarenju svojih ciljeva.
          </p>
        </div>
        <div className="tekstDiv">
          {" "}
          <p className="umetnik">Svetlana Mojić Džakula – arhitekta</p>
          <img className="slikaEdukutak" src={slika4} />
          <p>
            Svetlana je vlasnica studija Salt & Water i proslavila se dizajnom
            enterijera i eksterijera jahti. Sa preko deset godina iskustva u
            jedrenju, duboko je povezana sa vodom i morem. Nakon specijalizacije
            za dizajn jahti i konstrukcije, osnovala je svoj studio u Srbiji,
            fokusirajući se na neiskorišćene vodne resurse poput jezera, reka i
            kanala. Svoje znanje deli kroz predavanja o potencijalu unutrašnjih
            voda. Njen studio je dobio prestižne međunarodne nagrade,
            uključujući nagradu za dizajn enterijera Boeing 787 Vip Dreamliner-a
            na International Yachts & Aviation Awards. Svetlana je takođe
            nagrađena kao osnivač najboljeg startapa na Balkanu osnovanog od
            strane žene, dok je bila aktivna i kao sudija u jedriličarskoj
            reprezentaciji Srbije.
          </p>
        </div>
      </>
    ),
    slika: AImed1,
    datum: "10.5.2024.",
    autor: "Natalija Okrajnov",
    index: "uticajni-srbi-iz-sveta-dizajna",
  },
];
