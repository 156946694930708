import React from "react";
import "./Program.css";

const Program = () => {
  return (
    <div className="contentProgram">
      <div className="wrapper">
        <h1 className="uizradi">Uskoro će biti dostupno više informacija  :)</h1>
      </div>
    </div>
  );
};

export default Program;
