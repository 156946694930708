import React from "react";
import "./radionice.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaHeart } from "react-icons/fa";
import { useState } from "react";
import slika1 from "./Slike/GraphicDesignAdvanced/BDW19_AnaGligorijevi.jpg";
import slika2 from "./Slike/GraphicDesignAdvanced/BDW19_AnaPetrov.jpg";
import slika3 from "./Slike/GraphicDesignAdvanced/BDW19_DavidKi.jpg";
import slika4 from "./Slike/GraphicDesignAdvanced/BDW19_DunjaSavic.jpg";
import slika5 from "./Slike/GraphicDesignAdvanced/BDW19_Irina.jpg";

const GraphicDesignAdvanced = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [slika1, slika2, slika3, slika4, slika5];

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };

  return (
    <div className="meetup">
      <div className="contentMeetup">
        <p className="razmak1"></p>
        <div className="naslov-centar">
          <h1 className="naslov"> Graphic Design Advanced </h1>
        </div>
        <p className="razmak1"></p>

        <div className="section">
          <div className="left">
            <img
              src="https://custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_9000,w_1200,f_auto,q_auto/7220635/611086_694633.jpeg"
              alt="Graphic Design"
            />
          </div>
          <div className="right">
            <h1>KOME JE NAMENJENA RADIONICA I KOJI JE CILJ?</h1>
            <p>
              {" "}
              Graphic Design Advanced je radionica koja je prilagođena svim
              studentima koji žele da unaprede svoje znanje u grafičkom dizajnu
              i nauče nešto više o brendiranju – formiranju vizuelnog
              identiteta. Za ovu radionicu neophodno je predznanje iz ove
              oblasti i poznavanje softverskih programa, Adobe Photoshop i Adobe
              Illustrator, koji se koriste u dizajniranju. Cilj je da nakon 6
              dana polaznici prošire i unaprede svoja znanja i da tokom seminara
              kreiraju finalni proizvod koji će biti odraz njihovog rada i
              stečenih veština.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="left">
            <h1>KAKAV JE KONCEPT RADIONICE?</h1>
            <p>
              Radionica se sastoji iz teorijskog i praktičnog dela. U okviru
              teorijskog dela, učesnici će se uz pomoć predavača upoznati sa
              osnovama osmišljavanja vizuelnog identiteta i logoom kao
              konceptualnim zadatkom. Kasnije će svoje teorijsko znanje
              primeniti praktično, gde će im predavači pomoći da na računarima
              realizuju svoju ideju i dođu do najboljeg rešenja.Trajanje
              radionice: 6 dana (od 18. do 24. maja) Trajanje časa po danu: 1h i
              15 minuta + 30 minuta pauze + 1h i 15 minuta Maksimalni broj
              izostanaka sa kojim se dobija sertifikat o učešću na radionici je
              2. Neophodno je nošenje sopstvenog lapt
            </p>
          </div>
          <div className="right">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmXIWYCFXHHMavZdYKfXWyjlV5mUlwMX2ld-HAH_T5zw&s"
              alt="Digital Photography"
            />
          </div>
        </div>
        {/* <div className="section">
          <div className="predavac1">
            <img
              src="https://bdw.best.rs/wp-content/uploads/2023/04/Slavoljub-Cirkovic.png"
              alt="Slavoljub Cirkovic"
            />
          </div>
          <div className="right">
            <h1>Slavoljub Ćirković</h1>
            <p>
              Slavoljub Ćirković je grafički i web dizajner. Diplomirani
              ekonomista, koji je uplivao u vode grafičkog dizajna, kojim se
              uspešno bavi skoro 15 godina. Pobedio je na preko 50
              internacionalnih konkursa za grafički i web dizajn i još u preko
              90 bio u finalu na najpoznatijim svetskim platformama. Vlasnik je
              sopstvenog jedinstvenog brenda SCART-Design. Radi za poznate kako
              domaće, tako i strane firme, za Sportsko društvo Crvena zvezda,
              poznate blogere, fitness instruktore, glumce, sportiste…. Takođe
              spada u red top dizajnera, ne samo u Srbiji nego i u svetu, kad su
              u pitanju fotomanipulacije. Njegove fotomanipulacije koriste
              poznati sportski klubovi, nalaze se na muralima, bilbordima,
              tetovažama…
            </p>
            <div className="social-icons">
              <a href="https://www.facebook.com/best.beograd/">
                <FaFacebook />
              </a>
              <a href="https://www.instagram.com/best_belgrade/">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com/company/best-belgrade/">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div> */}
        <div className="naslov-centar">
          <h1 className="naslov1">Radovi učesnika</h1>
        </div>
        <div className="section"></div>
        <div className="slideshow-container5">
          <button className="prev" onClick={prevSlide}>
            &#10094;
          </button>
          <img
            className="slideshowslike"
            src={slides[currentSlide]}
            alt={`Slide ${currentSlide + 1}`}
          />
          <button className="next" onClick={nextSlide}>
            &#10095;
          </button>
        </div>
      </div>
      <div className="razmak"></div>
    </div>
  );
};

export default GraphicDesignAdvanced;
