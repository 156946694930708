import React from "react";
import "./radionice.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaHeart } from "react-icons/fa";
import { useState } from "react";
import slika1 from "./Slike/WebDesignBasic/WB1.png";
import slika2 from "./Slike/WebDesignBasic/WB5.png";
import slika3 from "./Slike/WebDesignBasic/WB6.png";
import slika4 from "./Slike/WebDesignBasic/WB7.png";
import slika5 from "./Slike/WebDesignBasic/WB8.png";

const WebDesignBasic = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [slika1, slika2, slika3, slika4, slika5];

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };

  return (
    <div className="meetup">
      <div className="contentMeetup">
        <p className="razmak1"></p>
        <div className="naslov-centar">
          <h1 className="naslov"> Web Design HTML/CSS </h1>
        </div>
        <p className="razmak1"></p>
        <div className="section">
          <div className="left">
            <img
              src="https://www.simplilearn.com/ice9/free_resources_article_thumb/How_to_Learn_Web_Designing.jpg"
              alt="Web Design"
            />
          </div>
          <div className="right">
            <h1 className="naslovh">
              KOME JE NAMENJENA RADIONICA I KOJI JE CILJ?
            </h1>
            <p className="naslovh">
              Web Design HTML/CSS je radionica namenjena studentima koji su
              zainteresovani da nauče osnove web dizajna. Za ovu radionicu nije
              neophodno predznanje samo dobra volja i spremnost za usvajanje
              novih znanja i veština. Cilj je da studenti steknu tehnička znanja
              – kako se pravi i kako funckioniše web sajt, koje tehnologije su
              za to potrebne, da savladaju osnove HTML-a i CSS-a.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="left">
            <h1>KAKAV JE KONCEPT RADIONICE?</h1>
            <p>
              Radionica se sastoji iz teorijskog i praktičnog dela. U okviru
              teorijskog dela, učesnici će se uz pomoć predavača upoznati sa
              osnovama web dizajna i radom HTML-a i CSS-a. Praktični deo će biti
              realizovan kroz primenu stečenog znanja, gde će uz pomoć predavača
              imati priliku za izradu zadataka za vežbu. Radionica će se održati
              kroz 7 dana (od 18. do 24. maja). Učesnici imaju pravo na
              maksimalno 2 izostanaka kako bi ostvarili pravo na sertifikat o
              učešću na radionici. Takođe, polaznici radionice su u obavezi da
              ponesu svoj laptop.
              {/* Radionica se sastoji iz teorijskog i praktičnog dela. U okviru
              teorijskog dela, učesnici će se uz pomoć predavača upoznati sa
              osnovama web dizajna i radom HTML-a i CSS-a. Praktični deo će biti
              realizovan kroz primenu stečenog znanja, gde će uz pomoć predavača
              imati priliku za izradu zadataka za vežbu. Trajanje radionice: 6
              dana (od 13. do 18. maja) Trajanje časa po danu: 1h i 15 minuta +
              30 minuta pauze + 1h i 15 minuta Maksimalni broj izostanaka sa
              kojim se dobija sertifikat o učešću na radionici je 2. */}
            </p>
          </div>
          <div className="right">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrKOJRMo4DcDhxQN5cuaTg-8WkO9yTa8ywc0bgVVTe_Q&s"
              alt="Digital Photography"
            />
          </div>
        </div>
        {/* <div className="section">
          <div className="predavac1">
            <img
              src="https://bdw.best.rs/wp-content/uploads/2023/04/marko-1-828x1024.jpg"
              alt="Marko"
            />
          </div>
          <div className="right">
            <h1>Marko Petrović</h1>
            <p>
              „Student i saradnik u nastavi na Fakultetu Organizacionih nauka.
              Rad u dizajn timu na projektima „Hakaton za srednjoškolce“ i „C2S“
              studentske organizacije FONIS.“
            </p>
            <div className="social-icons">
              <a href="https://www.facebook.com/best.beograd/">
                <FaFacebook />
              </a>
              <a href="https://www.instagram.com/best_belgrade/">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com/company/best-belgrade/">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div> */}
        <div className="naslov-centar">
          <h1 className="naslov1">Radovi učesnika</h1>
        </div>
        <div className="section"></div>
        <div className="slideshow-container5">
          <button className="prev" onClick={prevSlide}>
            &#10094;
          </button>
          <img
            className="slideshowslike"
            src={slides[currentSlide]}
            alt={`Slide ${currentSlide + 1}`}
          />
          <button className="next" onClick={nextSlide}>
            &#10095;
          </button>
        </div>
      </div>
      <div className="razmak"></div>
    </div>
  );
};

export default WebDesignBasic;
