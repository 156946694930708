export const elementsData = [
  {
    title: "Ko može da bude polaznik radionica?",
    additionalText:
      "Radionice mogu da pohađaju studenti svih fakulteta, bilo državnih ili privatnih, osnovnih ili master studija. ",
  },
  {
    title: "Da li je predznanje neophono za pohađanje radionica?",
    additionalText:
      "Za radionice naprednog nivoa (Graphic Design Advanced i Web Design React), određeno predznanje je neophodno, dok je za ostale radionice dovoljna samo želja za učenjem i napredovanjem. Više informacija o svakoj radionici možete pronaći u odeljku ,,Radionice''.",
  },
  {
    title: "Ko može da prisustvuje Design Insight-u?",
    additionalText:
      "Ova predavanja su otvorena za sve zainteresovane studente, bilo da su učesnici seminara ili ne.",
  },
  {
    title: "Da li su radionice besplatne?",
    additionalText:
      "Sav sadržaj BEST Design WEEK-a je potpuno besplatan.",
  },
  {
    title: "Ko i kako selektuje potencijalne polaznike radionica?",
    additionalText:
      "Predavači vrše selekciju na osnovu prijave učesnika/priloženih radova.",
  },
  {
    title: "Da li je neophodno prisustvovati radionicama svih sedam dana?",
    additionalText:
      "Primljeni polaznici mogu imati najviše dva izostanka sa radionica kako bi dobili sertifikat.",
  },
  {
    title: "Za koje radionice mogu da se prijavim?",
    additionalText:
      "U okviru BEST Design WEEK-a ove godine, održaće se 6 radionica. To su: Graphic Design Basic, Graphic Design Advanced, Web Design HTML/CSS, Web Design React, Digital Photography i Video Editing. Detaljnije o svakoj radionici možete videti u odeljku ,,Radionice''.",
  },
  {
    title: "Za koliko radionica mogu da se prijavim?",
    additionalText:
      "Moguće je poslati prijavu za najviše 2 radionice odnosno za jednu kao prvu želju i za još jednu ukoliko te nisu prihvatili za željenu. Učesnik će biti primljen na maksimalno jednu radionicu.",
  },
];
