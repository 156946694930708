import React from "react";
import "./radionice.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaHeart } from "react-icons/fa";
import { useState } from "react";
import slika1 from "./Slike/WebDesignAdvanced/WA1.png";
import slika2 from "./Slike/WebDesignAdvanced/WA4.png";
import slika3 from "./Slike/WebDesignAdvanced/WA5.png";
import slika4 from "./Slike/WebDesignAdvanced/WA6.jpg";

const WebDesignAdvanced = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [slika1, slika2, slika3, slika4];
  const nextSlide = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };
  return (
    <div className="meetup">
      <div className="contentMeetup">
        <div className="naslov-centar">
          <h1 className="naslov"> Web Design React </h1>
        </div>
        <p className="razmak1"></p>

        <div className="section">
          <div className="left">
            <img
              src="https://www.brandignity.com/wp-content/uploads/2016/07/web-design-tips.jpg"
              alt="Digital Photogrphy"
            />
          </div>
          <div className="right">
            <h1>KOME JE NAMENJENA RADIONICA?</h1>
            <p>
              {" "}
              Web Design React je radionica namenjena svim studentima koji već
              poznaju osnove web dizajna i žele da unaprede svoje znanje iz ove
              oblasti kroz primenu React-a.
              {/* Web Design React je radionica namenjena svim studentima koji već
              poznaju osnove web dizajna i žele da unaprede svoje znanje. */}
            </p>
          </div>
        </div>
        <div className="section">
          <div className="left">
            <h1>KAKAV JE KONCEPT RADIONICE?</h1>
            <p>
              Ova radionica će osnažiti učesnike sa relevantnim znanjem i
              veštinama za uspešan rad u svetu web dizajna. Sastoji se iz
              teorijskog i praktičnog dela kroz koje će učesnici imati priliku
              da se upoznaju sa osnovama programa React i razvojnim okruženjem.
              Učesnici će, primenjujući novostečena znanja i veštine o
              neophodnim alatima, na praktičnom delu kursa izraditi finalni rad,
              a kroz ceo proces vodiće ih predavači pružajući korisne sugestije
              i podršku. Radionica će se održati kroz 7 dana (od 18. do 24.
              maja). Učesnici imaju pravo na maksimalno 2 izostanaka kako bi
              ostvarili pravo na sertifikat o učešću na radionici. Takođe,
              polaznici radionice su u obavezi da ponesu svoj laptop.
              {/* Radionica se sastoji iz teorijskog i praktičnog dela kroz koje će
              učesnici imati prilike da uz pomoć predavača rade UX/UI (User
              eXperience/User Interface) dizajn kako bi učinili svoj sajt
              efektnim, dinamičnim i prepoznatljivim. Ova radionica je namenjena
              dizajnerima koji treba da dostave dobar dizajn developer-ima i
              developer-ima koji žele da razumeju bolje kako web dizajn
              funkcioniše. Trajanje radionice: 6 dana (od 13. do 18. maja)
              Trajanje časa po danu: 1h i 15 minuta + 30 minuta pauze + 1h i 15
              minuta Maksimalni broj izostanaka sa kojim se dobija sertifikat o
              učešću na radionici je 2. Neophodno je nošenje sopstvenog laptopa. */}
            </p>
          </div>
          <div className="right">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrynLlW6drLSmRKAGHhp9WWDx4l6FUsSlOQik1u9R5FkS_pWs1srvdOarhudxBJsZQ3z0&usqp=CAU"
              alt="Digital Photography"
            />
          </div>
        </div>
        {/* <div className="section">
          <div className="predavac1">
            <img
              src="https://bdw.best.rs/wp-content/uploads/elementor/thumbs/Copy-of-IMG_0064-q4yubf3i9a18evrqdvfzb0zh2ox63o8zlqz1lwzga4.jpg"
              alt="Katarina Markovic"
            />
          </div>
          <div className="right">
            <h1>Nikola Tripković</h1>
            <p>
              Pre 3 godine, Nauči Dizajn krenuo je kao misija da ljudima
              proširim moju veštinu i strast. Danas Nauči Dizajn ima 10
              zaposlenih i preko 1000 učenika. Desetine ljudi pronašle su
              vrhunski posao, a krenuli su od nule sa mojim kursevima. Moj cilj
              je da ekipa sa BEST radionica bude sledeća!
            </p>
            <div className="social-icons">
              <a href="https://www.facebook.com/best.beograd/">
                <FaFacebook />
              </a>
              <a href="https://www.instagram.com/best_belgrade/">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com/company/best-belgrade/">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div> */}
        <div className="naslov-centar">
          <h1 className="naslov1">Radovi učesnika</h1>
        </div>
        <div className="section"></div>
        <div className="slideshow-container5">
          <button className="prev" onClick={prevSlide}>
            &#10094;
          </button>
          <img
            className="slideshowslike"
            src={slides[currentSlide]}
            alt={`Slide ${currentSlide + 1}`}
          />
          <button className="next" onClick={nextSlide}>
            &#10095;
          </button>
        </div>
      </div>
      <div className="nina"></div>
    </div>
  );
};
export default WebDesignAdvanced;
