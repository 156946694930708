import React, { useState } from "react";
import "./DesignInsight.css";
import { meetupData } from "./DesignInsightData.js";
import { Link } from "react-router-dom";

const Meetup = () => {
  // const [currentSlide, setCurrentSlide] = useState(0);

  // const nextSlide = () => {
  //   if (currentSlide < meetupData[0].slike.length - 1) {
  //     setCurrentSlide(currentSlide + 1);
  //   }
  // };

  // const prevSlide = () => {
  //   if (currentSlide > 0) {
  //     setCurrentSlide(currentSlide - 1);
  //   }
  // };

  return (
    <div className="meetup">
      <div className="header-razmak"></div>

      {meetupData.map((item, index) => (
        <div className="contentMeetup" key={index}>
          <div className="naslov-centar">
            <h1 className="naslovStranice">{item.naslov}</h1>
          </div>
          <p className="text">{item.tekst}</p>
          {/* <div className="noviDiv">
            {item.predavaci.map((predavac, idx) => (
              <div className="predavac" key={idx}>
                <div className="predavacInfo">
                  <h2 className="naslov-centar">{predavac.ime}</h2>
                  <p className="text">{predavac.opis}</p>
                </div>
                <img
                  src={predavac.slika}
                  alt={predavac.ime}
                  className="predavacSlika"
                />
              </div>
            ))}
          </div> */}
          {/* <div className="dugmePozicija">
            <Link to="/prijava-design-insight">
              <button className="dugme">Prijavi se Design Insight</button>
            </Link>
          </div> */}
        </div>
      ))}
      <div className="footer-razmak"></div>
    </div>
  );
};

export default Meetup;
