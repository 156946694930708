import React from 'react';
import FRimage from './SlikeOrganizatori/FR.jpg';
import KSimage from './SlikeOrganizatori/KS.jpg';
import LOGimage from './SlikeOrganizatori/LOG.jpg';
import MOimage from './SlikeOrganizatori/MO.jpg';
import PRimage from './SlikeOrganizatori/PR.jpg';
import PUBimage from './SlikeOrganizatori/PUB.jpg';

import { FaLinkedin, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

import "./Organizatori.css";




//function Organizatori(){
const Organizatori = () => {
  return (
    <div className="organizatori">

      <div className="flex-container">
        <div className="div-gornji">
          <h2 className="naslov-div">BEST</h2>
          <p className="tekst">
            Organizator BEST design WEEK-a je Udruženje studenata tehnike Evrope – Beograd,
            koje je član mreže BEST (Board of European Students of Technology),
            neprofitne i apolitične studentske organizacije čija je misija razvijanje studenata i ona obuhvata 87 lokalnih BEST grupa u 30 zemlje Evrope.
          </p>
        </div>

        <div className="div-donji">
          <h2 className="naslov-div">BEST Beograd</h2>
          <p className="tekst">
            Lokalna grupa BEST Beograd osnovana je novembra 2003. od strane entuzijastičnih studenata koji su želeli da dovedu Evropu u Beograd.
            Na ovaj način omogućili su studentima tehničko-tehnoloških, prirodno-matematičkih fakulteta i farmacije da putuju po Evropi,
            upoznaju nove kulture i steknu dodatno obrazovanje, bilo u ili van svoje struke.
            BEST Beograd danas je jedna od najprepoznatljivih studentskih organizacija na Univerzitetu u Beogradu. Kreativni,
            mladi članovi rade na organizovanju stručnih kurseva i kulturnih razmena, inženjerskih takmičenja i seminara. BEST Beograd omogućava povezivanje,
            razmenu i saradnju sa studentima iz cele Evrope.
            BEST design WEEK pripada grupi lokalnih događaja koje BEST sprovodi tokom godine.
          </p>
          <a href="https://best.rs/" className="dugme">
            Vise o nama
          </a>
        </div>
      </div>

      <div className='Org'>
        <div className="red prvi-red">
          <div className="slika-container">
            <img src={MOimage} alt="Glavni organizator" className='slikeOrg' />
            <div className="tekst-overlay">
              <p className='organizatori'> <strong>Anđela Kovačević – Glavni organizator</strong></p>
              <a href="mailto:bdw.mo@best.rs">
                <FaEnvelope /> bdw.mo@best.rs
              </a>
              <br />
              <a href="tel:+381621373040"> <FaPhoneAlt /> +381 62 1373040</a>
              <br />
              <a href="https://www.linkedin.com/in/an%C4%91ela-kova%C4%8Devi%C4%87">
                <FaLinkedin /> LinkedIn profil
              </a>
            </div>
          </div>

          <div className="slika-container">
            <img src={KSimage} alt="Koordinator seminara" className='slikeOrg' />
            <div className="tekst-overlay">
              <p className='organizatori'><strong>Una Kovačević – Koordinator seminara</strong></p>
              <a href="mailto:bdw.ks@best.rs">
                <FaEnvelope /> bdw.ks@best.rs
              </a>
              <br />
              <a href="tel:+381 69 5410500"> <FaPhoneAlt /> +381 69 5410500</a>
              <br />
              <a href="https://www.linkedin.com/in/una-kova%C4%8Devi%C4%87-154ab62a6/">
                <FaLinkedin /> LinkedIn profil
              </a>

            </div>
          </div>

          <div className="slika-container">
            <img src={FRimage} alt="Koordinator za odnose sa kompanijama" className='slikeOrg' />
            <div className="tekst-overlay">
              <p className='organizatori'><strong>Anđela Petričković – Koordinator za odnose sa kompanijama</strong></p>
              <a href="mailto:bdw.cr@best.rs">
                <FaEnvelope /> bdw.cr@best.rs
              </a>
              <br />
              <a href="tel:+381 69 5466169"> <FaPhoneAlt /> +381 69 5466169</a>
              <br />
              <a href="https://www.linkedin.com/in/andjela-petrickovic-468067259/">
                <FaLinkedin /> LinkedIn profil
              </a>

            </div>
          </div>
        </div>

        <div className="red drugi-red">

          <div className="slika-container">
            <img src={PRimage} alt="Koordinator za marketing i odnose sa javnošću" className='slikeOrg' />
            <div className="tekst-overlay">
              <p className='organizatori'><strong>Natalija Okrajnov – Koordinator za marketing i odnose sa javnošću</strong></p>
              <a href="mailto:bdw.pr@best.rs">
                <FaEnvelope /> bdw.pr@best.rs
              </a>
              <br />
              <a href="tel:+381 65 4261571"> <FaPhoneAlt /> +381 65 4261571</a>
              <br />
              <a href="https://www.linkedin.com/in/natalija-okrajnov-a2655b2b3/">
                <FaLinkedin /> LinkedIn profil
              </a>

            </div>
          </div>

          <div className="slika-container">
            <img src={PUBimage} alt="Koordinator za dizajn" className='slikeOrg' />
            <div className="tekst-overlay">
              <p className='organizatori'><strong>Teodora Stanojević – Koordinator za dizajn</strong></p>
              <a href="mailto:bdw.pub@best.rs">
                <FaEnvelope /> bdw.pub@best.rs
              </a>
              <br />
              <a href="tel:+381 65 4352885"> <FaPhoneAlt /> +381 65 4352885</a>
              <br />
              <a href="https://www.linkedin.com/in/teodorastanojevic/">
                <FaLinkedin /> LinkedIn profil
              </a>

            </div>
          </div>

          <div className="slika-container">
            <img src={LOGimage} alt="Koordinator za logistiku" className='slikeOrg' />
            <div className="tekst-overlay">
              <p className='organizatori'><strong>Pavle Bićanin – Koordinator za logistiku</strong></p>
              <a href="mailto:bdw.log@best.rs">
                <FaEnvelope /> bdw.log@best.rs
              </a>
              <br />
              <a href="tel:+381 66070801"> <FaPhoneAlt /> +381 66070801</a>
              <br />
              <a href="https://rs.linkedin.com/in/pavle-bi%C4%87anin">
                <FaLinkedin /> LinkedIn profil
              </a>

            </div>
          </div>
        </div>

      </div>

    </div >
  );

};

export default Organizatori;
