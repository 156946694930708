import "./HomeStyles.css";
import logoBDW from "./SlikeHome/BDWwhite.png";
import FON from "./SlikeHome/FON.png";
import Mozzart from "./SlikeHome/Mozzart.png";
// import logoBDW from "./SlikeHome/BDW.png";
import CountdownTimer from "./CountdownTimer/CountdownTimer";
import Card from "./Cards/Card.js";
import CardNagrade from "./Cards/CardNagrade.js";
import Pokrovitelji from "./Cards/Pokrovitelji";
import { cardData } from "./Cards/cardData";
import { cardNagradeData } from "./Cards/cardNagradeData";
//import { PokroviteljiData } from "./Cards/PokroviteljiData.js";
import Pitanja from "./Cards/Pitanja";
import { elementsData } from "./Cards/elementsData";

function Home() {
  return (
    <div className="home-container">
      <div className="razmakhome"></div>
      <div className="home">
        <div className="BDW">
          <img src={logoBDW} alt="Logo BDW" className="LogoHome" />
          <h2 className="datum">18. - 24. MAJ</h2>
          <h1 className="svuda">DIZAJN JE SVUDA!</h1>
        </div>

        <div className="timer">
          {/* <div className="timerrazmak"></div> */}
          <h2>BDW POČINJE ZA</h2>
          <CountdownTimer />
          {/* <a href="/prijava" className="razmakTakmicenje">
            <button className="dugme">PRIJAVI SE</button>
          </a> */}
          <div className="timerrazmak"></div>
          <div className="timerrazmak"></div>
          <div className="timerrazmak"></div>
        </div>

        <div className="pozadina2">
          <div className="card-container">
            {cardData.map((card) => (
              <Card
                title={card.title}
                content={card.content}
                button={card.button}
              />
            ))}
          </div>
        </div>

        <div className="timerrazmak"></div>
        <div className="pokrovitelj-naslov">
          <h2 className="pokrovitelj-naslov-tekst">RADIONICE</h2>
        </div>
        <div className="cardNagrade-container">
          {cardNagradeData.map((cardNagrade) => (
            <CardNagrade
              title={cardNagrade.title}
              content={cardNagrade.content}
              imageUrl={cardNagrade.imageUrl}
              button={cardNagrade.button}
              buttons={cardNagrade.buttons}
            />
          ))}
        </div>

        <div className="razmakhome"></div>
        <div className=" podrzali-container ">
          <div className="pokrovitelj-naslov">
            <h2 className="pokrovitelj-naslov-tekst">PROJEKAT PODRŽALI</h2>
          </div>

          <div className="pokrovitelj-naslov">
            <a href="https://fon.bg.ac.rs/" target="_blank">
              <img src={FON} alt="FON" className="FONpocetna" />
            </a>
            <a href="https://www.mozzartbet.com/sr#/" target="_blank">
              <img src={Mozzart} alt="Mozzart" className="MozzartPocetna" />
            </a>
          </div>
        </div>
        <div className="timerrazmak"></div>
        {/* <div className="pokrovitelji">
          {PokroviteljiData.map((pokroviteljiCard, index) => (
            <Pokrovitelji key={index} imageUrl={pokroviteljiCard.imageUrl} />
          ))}
        </div> */}

        <div className="pozadina2">
          <div className="cestoPostavljenaPitanja">
            <h2 className="pitanjaNaslov">ČESTO POSTAVLJENA PITANJA</h2>
            <Pitanja elementsData={elementsData} />
            <p className="dodatnoKO">
              Ukoliko imate neko pitanje za nas, obratite nam se putem formе na
              stranici{" "}
              <a className="linkKO" href="/kontakt">
                Kontakt
              </a>{" "}
              ili nekom od{" "}
              <a href="/organizatori" className="linkKO">
                Organizatora
              </a>
              .
            </p>
          </div>
          <div className="razmakhome"></div>
        </div>
      </div>
      <div className="timerrazmak"></div>
    </div>
  );
}

export default Home;
