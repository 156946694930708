import card1 from "../SlikeHome/digitalPhotography.png";
import card2 from "../SlikeHome/graphicDesign.png";
import card3 from "../SlikeHome/videoEditing.png";
import card4 from "../SlikeHome/webDesign.png";

export const cardNagradeData = [
  {
    title: "Digital Photography",
    // content: "100.000 RSD",
    imageUrl: card1,
    button: {
      text: "Digital Photography",
      link: "/radionice/digital-photography",
    },
  },
  {
    title: "Graphic Design",
    // content: "50.000 RSD",
    imageUrl: card2,
    buttons: [
      {
        text: "Graphic Design - Basic",
        link: "/radionice/graphic-design-basic",
      },
      {
        text: "Graphic Design - Advanced",
        link: "/radionice/graphic-design-advanced",
      },
    ],
  },
  {
    title: "Video Editing",
    // content: "25.000 RSD",
    imageUrl: card3,
    button: { text: "Video Editing", link: "/radionice/video-editing" },
  },
  {
    title: "Web Design",
    // content: "25.000 RSD",
    imageUrl: card4,
    buttons: [
      { text: "Web Design - HTML/CSS", link: "/radionice/web-design-HTML/CSS" },
      { text: "Web Design - React", link: "/radionice/web-design-react" },
    ],
  },
];
