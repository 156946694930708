import React from "react";
import { Link } from "react-router-dom";
import "../Vesti.css";
import "./Edu.css";
import { EdukutakData } from "./EdukutakData";

const Edukutak = () => {
  // const vesti = VestiData;

  return (
    <div className="pozadina-vesti">
      <div className="razmakhome"></div>
      <div className="vesti-container">
        {EdukutakData.map((edukutak) => (
          <div className="vest-card" key={edukutak.index}>
            <img
              className="slikaEdukutak"
              src={edukutak.slika}
              alt={edukutak.naslov}
            />
            <h2>{edukutak.naslov}</h2>
            <p>{edukutak.kratakSadrzaj}</p>
            <Link
              to={{
                pathname: `/edukutak/${edukutak.index}`,
                state: { edukutak },
              }}
            >
              <button className="dugme-vesti dugme-dno">Prikaži više</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Edukutak;
