import React from "react";
import "./radionice.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaHeart } from "react-icons/fa";
import { useState } from "react";
//import snimak1 from "./Slike/VideoEditing/VE3.mp4";
//import snimak2 from "./Slike/VideoEditing/VE5.mp4";
//import snimak3 from "./Slike/VideoEditing/VE7.mp4";
//import snimak4 from "./Slike/VideoEditing/VE8.mp4";
import snimak5 from "./Slike/VideoEditing/VE9.mp4";
import snimak6 from "./Slike/VideoEditing/VE10.mp4";
import snimak7 from "./Slike/VideoEditing/VE11.mp4";
import snimak8 from "./Slike/VideoEditing/VE12.mp4";
//import snimak9 from "./Slike/VideoEditing/VE13.mp4";
import snimak10 from "./Slike/VideoEditing/VE14.mp4";
// import snimak11 from "./Slike/VideoEditing/VE15.mp4";
// import snimak12 from "./Slike/VideoEditing/VE16.mp4";
//import snimak13 from "./Slike/VideoEditing/VE17.mp4";
//import snimak14 from "./Slike/VideoEditing/VE18.mp4";
//import snimak15 from "./Slike/VideoEditing/VE19.mp4";
//import snimak16 from "./Slike/VideoEditing/VE20.mp4";
//import snimak17 from "./Slike/VideoEditing/VE21.mp4";
//import snimak18 from "./Slike/VideoEditing/VE22.mp4";
//import snimak19 from "./Slike/VideoEditing/VE23.mp4";
//import snimak20 from "./Slike/VideoEditing/VE24.mp4";
 //import snimak21 from "./Slike/VideoEditing/VE25.mp4";
 //import snimak22 from "./Slike/VideoEditing/VE26.mp4";

const VideoEditing = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
   const slides = [ snimak5, snimak6, snimak7, snimak8,  snimak10, ];
 // snimak1, snimak2, snimak3, snimak4,snimak11, snimak12, snimak21 ,snimak20, snimak9, snimak13, snimak14, snimak15, snimak16, snimak17, snimak18, snimak19, snimak22
  const nextSlide = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };
  return (
    <div className="meetup">
      <div className="contentMeetup">
        <p className="razmak1"></p>
        <div className="naslov-centar">
          <h1 className="naslov"> Video Editing </h1>
        </div>
        <p className="razmak1"></p>

        <div className="section">
          <div className="left">
            <img
              src="https://i.rtings.com/assets/pages/OICDg5Ss/best-video-editing-laptops-20220921-2-medium.jpg?format=auto"
              alt="Digital Photogrphy"
            />
          </div>
          <div className="right">
            <h1>KOME JE NAMENJENA RADIONICA I KOJI JE CILJ?</h1>
            <p>
              {" "}
              Video Editing je radionica namenjena svim studentima koji žele da
              nauče nešto o video-montaži, audio-produkciji, kreiranju grafika i
              korišćenju video efekata i tranzicija. Fokus je na korišćenju
              softvera Adobe Premiere Pro. Savladavanje video editinga je od
              ključne važnosti ukoliko vas zanima produkcija. Za ovu radionicu
              vam nije potrebno nikakvo predznanje.Cilj je da nakon završenog
              seminara studenti savladaju razne alate iz Adobe serije i da
              uspešno namontiraju snimke i pretvore u uređene video zapise –
              filmove.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="left">
            <h1>KAKAV JE KONCEPT RADIONICE?</h1>
            <p>
              Radionica se sastoji iz teorijskog i praktičnog dela. Koncipirana
              je tako da se od početka upoznate sa video montažom i prilikama
              koje vam ona otvara. U okviru teorijskog dela stiču znanje o tome
              šta je sve potrebno za editovanje dobrog kadra kao i znanje o
              drugim sastavnim komponentama kao što su muzika i vizuali, dok će
              praktični deo obuhvatiti primenu stečenog znanja. Trajanje
              radionice: 6 dana (od 18. do 24. maja) Trajanje časa po danu: 1h i
              15 minuta + 30 minuta pauze + 1h i 15 minuta Maksimalni broj
              izostanaka sa kojim se dobija sertifikat o učešću na radionici je
              2.
            </p>
          </div>
          <div className="right">
            <img
              src="https://d26oc3sg82pgk3.cloudfront.net/files/media/edit/image/50798/article_full%402x.jpg"
              alt="Digital Photography"
            />
          </div>
        </div>
        {/* <div className="section">
          <div className="predavac1">
            <img
              src="https://bdw.best.rs/wp-content/uploads/2023/04/Copy-of-Dunia-Sweidan-Fotka-za-sajt-1024x1024.jpg"
              alt="Dunia"
            />
          </div>
          <div className="right">
            <h1>Dunia Sweidan</h1>
            <p>
              Dunia Sweidan je rođena u Jordanu 1994., potom se sa 5 godina
              preselila u Egipat gde je živela do svoje 18. i nakon toga je
              došla u Srbiju da upiše fakultet. Upisala je Fakultet za Medije i
              Komunikacije zato što je htela da se bavi fotografijom, tokom
              studija je primetila da joj grafički dizajn dosta leži ali na
              kraju je ipak shvatila da je njena ljubav video montaža. 2015. je
              odlučila da pauzira fakultet i zaposlila se na Kopernikus
              televiziji gde je radila 2 godine kao montažer informativnog i
              zabavnog programa. 2017. je prešla na Prvu televiziju gde radi i
              danas kao montažer u sektoru Promocije montirajući trejlere za
              filmove, serije i emisije. Od početka njene karijere na Prvoj
              televiziji je izmontirala preko 300 trejlera za filmove, 200
              trejlera za emisije i serije i 10 trejlera koji su se koristili za
              nacionalne kampanje. Trenutno je glavni montažer za trejlere na
              televiziji B92 koja je pod istim vlasništvom kao i Prva
              televizija. 2020. je odlučila da se vrati na fakultet i školuje se
              za audio vizuelnog producenta. Trudi se da svaki dan unapredi
              svoje znanje i veštine vezane za svoj posao. Vodi se mantrom
              “nikad se ne zna zašto je to dobro.”
            </p>
            <div className="social-icons">
              <a href="https://www.facebook.com/best.beograd/">
                <FaFacebook />
              </a>
              <a href="https://www.instagram.com/best_belgrade/">
                <FaInstagram />
              </a>
              <a href="https://www.linkedin.com/company/best-belgrade/">
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div> */}
        <div className="naslov-centar">
          <h1 className="naslov1">Radovi učesnika</h1>
        </div>
        <div className="section">
          <div className="slideshow-container5">
            <button className="prev" onClick={prevSlide}>
              &#10094;
            </button>
            <video
              className="slideshowslike"
              controls
              src={slides[currentSlide]}
              type="video/mp4"
            >
              Your browser does not support the video tag.
            </video>
            <button className="next" onClick={nextSlide}>
              &#10095;
            </button>
          </div>
        </div>
      </div>
      <div className="razmak"></div>
    </div>
  );
};

export default VideoEditing;
