//import logo from './logo.svg';
import "./App.css";
import React from "react";
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "./Components/Nav/Navbar";
import NavbarData from "./Components/Nav/NavbarData";
//import { NavbarData } from './Components/Nav/MenuData';

// import Vesti from './Components/Vesti/Vesti';
// import EduVestiData from './Components/Vesti/EduVesti/EduVestiData';
// import NovostiData from './Components/Vesti/Novosti/VestiData';
// import PojedinacneVesti from './Components/Vesti/PojedinacneVesti';

import PojedinacneVesti from "./Components/Vesti/PojedinacneVesti";
import { VestiData } from "./Components/Vesti/Novosti/VestiData";
import { EdukutakData } from "./Components/Vesti/Edukutak/EdukutakData";
import Vesti from "./Components/Vesti/Vesti";
import Edukutak from "./Components/Vesti/Edukutak/Edukutak";
import Pocetna from "./Components/Home/Home";
import Organizatori from "./Components/Organizatori/Organizatori";
// import Galerija from "./Components/Galerija/Galerija";
// import Pocetna from './Components/Pocetna/Pocetna';
import Footer from "./Components/Footer/Footer";
import Prijava from "./Components/Prijava/Prijava";
import PrijavaDI from "./Components/Prijava/DesignInsight/PrijavaDI";
import Program from "./Components/Program/Program";
// import Radionice from "./Components/Radionice/Radionice";
import WebDesignBasic from "./Components/Radionice/WebDesignBasic";
import WebDesignAdvanced from "./Components/Radionice/WebDesignAdvanced";
import GraphicDesignBasic from "./Components/Radionice/GraphicDesignBasic";
import GraphicDesignAdvanced from "./Components/Radionice/GraphicDesignAdvanced";
import DigitalPhotography from "./Components/Radionice/DigitalPhotography";
import VideoEditing from "./Components/Radionice/VideoEditing";
import DesignInsight from "./Components/DesignInsight/DesignInsight";
import Kontakt from "./Components/Kontakt/Kontakt";
import Partneri from "./Components/Partneri/Partneri";

function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Best Design Week - BDW Belgrade</title>
                </Helmet>
                <Pocetna />
              </>
            }
          />
          <Route
            path="/organizatori"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Organizatori - BDW Belgrade</title>
                </Helmet>
                <Organizatori />
              </>
            }
          />

          <Route
            path="/edukutak"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Edukutak - BDW Belgrade</title>
                </Helmet>
                <Edukutak />
              </>
            }
          />
          <Route
            path="/vesti"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Novosti - BDW Belgrade</title>
                </Helmet>
                <Vesti />
              </>
            }
          />

          {VestiData.map((vest) => (
            <Route
              path={`/vesti/${vest.index}`}
              element={<PojedinacneVesti pojedinacnaVest={vest} />}
            />
          ))}

          {EdukutakData.map((edukutak) => (
            <Route
              path={`/edukutak/${edukutak.index}`}
              element={<PojedinacneVesti pojedinacnaVest={edukutak} />}
            />
          ))}

          <Route
            path="/pocetna"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Pocetna - BDW Belgrade</title>
                </Helmet>
                <Pocetna />
              </>
            }
          />
          {/* <Route
            path="/prijava"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Prijava za radionice - BDW Belgrade</title>
                </Helmet>
                <Prijava />
              </>
            }
          />
          <Route
            path="/prijava-design-insight"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Prijava za Design Insight - BDW Belgrade</title>
                </Helmet>
                <PrijavaDI />
              </>
            }
          /> */}
          <Route
            path="/projekat/program"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Program - BDW Belgrade</title>
                </Helmet>
                <Program />
              </>
            }
          />
          <Route path="/radionice" />
          <Route
            path="/radionice/web-design-HTML/CSS"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Web Design HTML/CSS - BDW Belgrade</title>
                </Helmet>
                <WebDesignBasic />
              </>
            }
          />
          <Route
            path="/radionice/web-design-react"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Web Design React - BDW Belgrade</title>
                </Helmet>
                <WebDesignAdvanced />
              </>
            }
          />
          <Route
            path="/radionice/graphic-design-basic"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Graphic Design Basic - BDW Belgrade</title>
                </Helmet>
                <GraphicDesignBasic />
              </>
            }
          />
          <Route
            path="/radionice/graphic-design-advanced"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Graphic Design Advanced - BDW Belgrade</title>
                </Helmet>
                <GraphicDesignAdvanced />
              </>
            }
          />
          <Route
            path="/radionice/digital-photography"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Digital Photography - BDW Belgrade</title>
                </Helmet>
                <DigitalPhotography />
              </>
            }
          />
          <Route
            path="/radionice/video-editing"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Video Editing - BDW Belgrade</title>
                </Helmet>
                <VideoEditing />
              </>
            }
          />
          <Route
            path="/projekat/design-insight"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Design Insight - BDW Belgrade</title>
                </Helmet>
                <DesignInsight />
              </>
            }
          />
          <Route
            path="/kontakt"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Kontakt - BDW Belgrade</title>
                </Helmet>
                <Kontakt />
              </>
            }
          />
          <Route
            path="/partneri"
            element={
              <>
                {" "}
                <Helmet>
                  <title>Partneri - BDW Belgrade</title>
                </Helmet>
                <Partneri />
              </>
            }
          />
          {/* <Route path="/projekat/galerija" element={<Galerija />} /> */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
